/**
 * Strains calendar configuration
 */
export const strainsCalendar = [
  {
    month: 'January',
    year: '2024',
    strains: ['Mafia Funeral', 'Bubble Bath'],
  },
  {
    month: 'February',
    year: '2024',
    strains: ['Bubble Bath', 'Mac 1'],
  },
  {
    month: 'March',
    year: '2024',
    strains: ['Deep Fried Ice Cream', 'Mint Chocolate Chip'],
  },
  {
    month: 'April',
    year: '2024',
    strains: ['Mac 1', 'Grass Valley Girl'],
  },
  {
    month: 'May',
    year: '2024',
    strains: ['Deep Fried Ice Cream', 'Gary Payton'],
  },
  {
    month: 'June',
    year: '2024',
    strains: ['Bubble Bath', 'Banana Acai Mints'],
  },
  {
    month: 'July',
    year: '2024',
    strains: ['Kool Whip', 'Grass Valley Girl'],
  },
  {
    month: 'August',
    year: '2024',
    strains: ['Mint Chocolate Chip', 'MAC 1'],
  },
  {
    month: 'September',
    year: '2024',
    strains: ['Grass Valley Girl', 'Mint Chocolate Chip'],
  },
  {
    month: 'October',
    year: '2024',
    strains: ['Deep Fried Ice Cream', 'Grass Valley Girl'],
  },
  {
    month: 'November',
    year: '2024',
    strains: ['Banana Acai Mints'],
  },
  {
    month: 'December',
    year: '2024',
    strains: ['Mint Chocolate Chip'],
  },
  /**
   * Start 2025
   */
  {
    month: 'January',
    year: '2025',
    strains: ['MAC 1', 'Grass Valley Girl'],
  },
  {
    month: 'February',
    year: '2025',
    strains: ['Deep Fried Ice Cream', 'Jelly Donutz'],
  },
  {
    month: 'March',
    year: '2025',
    strains: [],
  },
  {
    month: 'April',
    year: '2025',
    strains: [],
  },
]

export const archivedCalendars = {
  2022: [
    {
      month: 'January',
      year: '2022',
      strains: ['apple fritter', 'bakers man', 'jokerz'],
    },
    {
      month: 'February',
      year: '2022',
      strains: [
        'Apple Fritter',
        'Mint Chocolate Chip',
        'Gorilla Glue 4 R.I.L.',
      ],
    },
    {
      month: 'March',
      year: '2022',
      strains: ['Concord Cream', 'Humboldt Dream', 'MAC 1', 'Don Carlos'],
    },
    {
      month: 'April',
      year: '2022',
      strains: [
        'Bakers Man',
        'Concord Cream',
        'Vanilla Cream Pie',
        'Marzhmallow',
      ],
    },
    {
      month: 'May',
      year: '2022',
      strains: ['Vanilla Cream Pie', 'Marzhmallow'],
    },
    {
      month: 'June',
      year: '2022',
      strains: [
        'Bakers Man',
        'Don Carlos',
        'Caramel Apple Gelato',
        'Mint Chocolate Chip',
      ],
    },
    {
      month: 'July',
      year: '2022',
      strains: ['Mac 1', 'Gazzurple', 'Grass Valley Girl'],
    },
    {
      month: 'August',
      year: '2022',
      strains: ['Apple Fritter', 'Mac 1'],
    },
    {
      month: 'September',
      year: '2022',
      strains: ['Marzhmallow', 'MAC 1'],
    },
    {
      month: 'October',
      year: '2022',
      strains: ['Bakers Man', 'Grass Valley Girl'],
    },
    {
      month: 'November',
      year: '2022',
      strains: ['Mac 1', 'Gazzurple'],
    },
    {
      month: 'December',
      year: '2022',
      strains: ['Mint Chocolate Chip', 'Mitten Tea'],
    },
  ],
  2023: [
    {
      month: 'January',
      year: '2023',
      strains: ['MAC 1', 'Marzhmallow'],
    },
    {
      month: 'February',
      year: '2023',
      strains: ['MAC 1', 'Bakers Man'],
    },
    {
      month: 'March',
      year: '2023',
      strains: ['Grass Valley Girl'],
    },
    {
      month: 'April',
      year: '2023',
      strains: ['Mitten Tea'],
    },
    {
      month: 'May',
      year: '2023',
      strains: ['Mint Chocolate Chip', 'Marzhmallow'],
    },
    {
      month: 'June',
      year: '2023',
      strains: ['Marzhmallow', 'Bakers Man'],
    },
    {
      month: 'July',
      year: '2023',
      strains: ['Bakers Man', 'Mint Chocolate Chip'],
    },
    {
      month: 'August',
      year: '2023',
      strains: ['Marzhmallow', 'Kool Whip', 'MAC 1'],
    },
    {
      month: 'September',
      year: '2023',
      strains: ['MAC 1', 'Banana Acai Mints'],
    },
    {
      month: 'October',
      year: '2023',
      strains: ['Grass Valley Girl', 'Bakers Man'],
    },
    {
      month: 'November',
      year: '2023',
      strains: ['SyrupZ', 'Gary Payton'],
    },
    {
      month: 'December',
      year: '2023',
      strains: ['Bubble Bath', 'Deep Fried Ice Cream'],
    },
  ],
}
